// import React, { useState } from 'react';
// import {
//   LogIn,
//   UserCircle2,
//   Building2,
//   School,
//   GraduationCap,
//   ArrowLeft,
//   X,
// } from 'lucide-react';

// const serviceData = {
//   talent: {
//     title: 'Talent Sign In',
//     description:
//       'Access your career dashboard, track applications, and manage your profile',
//     icon: UserCircle2,
//     color: 'teal',
//     features: [
//       'Track your job applications',
//       'Access learning resources',
//       'Connect with employers',
//       'View upcoming interviews',
//     ],
//     subdomain: 'talent.yourdomain.com',
//   },
//   employer: {
//     title: 'Employer Sign In',
//     description: 'Manage your recruitment pipeline and connect with top talent',
//     icon: Building2,
//     color: 'blue',
//     features: [
//       'Post job openings',
//       'Review applications',
//       'Schedule interviews',
//       'Access talent pool',
//     ],
//     subdomain: 'employer.yourdomain.com',
//   },
//   trainer: {
//     title: 'Trainer Sign In',
//     description: 'Manage your courses and track student progress',
//     icon: School,
//     color: 'purple',
//     features: [
//       'Manage course content',
//       'Track student progress',
//       'Schedule sessions',
//       'Generate reports',
//     ],
//     subdomain: 'trainer.yourdomain.com',
//   },
//   university: {
//     title: 'University Partner Sign In',
//     description: 'Collaborate on educational programs and track partnerships',
//     icon: GraduationCap,
//     color: 'orange',
//     features: [
//       'Manage partnership programs',
//       'Track student placements',
//       'Access resources',
//       'Generate analytics',
//     ],
//     subdomain: 'university.yourdomain.com',
//   },
// };

// const SignInPortal = ({ service, onClose }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [isLoading, setIsLoading] = useState(false);

//   // Default to talent if invalid service is provided
//   const currentService = serviceData[service] ? service : 'talent';
//   const data = serviceData[currentService];
//   const IconComponent = data.icon;

//   const colorClasses = {
//     teal: 'bg-teal-500 hover:bg-teal-600 focus:ring-teal-500',
//     blue: 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-500',
//     purple: 'bg-purple-500 hover:bg-purple-600 focus:ring-purple-500',
//     orange: 'bg-orange-500 hover:bg-orange-600 focus:ring-orange-500',
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     // Simulate API call
//     await new Promise((resolve) => setTimeout(resolve, 1000));
//     // Redirect to respective subdomain
//     window.location.href = `https://${data.subdomain}/dashboard`;
//   };

//   return (
//     <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
//       <div className="bg-white rounded-2xl w-full max-w-md relative overflow-y-auto max-h-[90vh]">
//         {/* Close button */}
//         <button
//           onClick={onClose}
//           className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full"
//         >
//           <X className="w-5 h-5 text-gray-500" />
//         </button>

//         <div className="p-6">
//           <div className="flex items-center justify-center space-x-2 mb-6">
//             <IconComponent className={`w-8 h-8 text-${data.color}-500`} />
//             <h2 className="text-2xl font-bold text-gray-900">{data.title}</h2>
//           </div>

//           <p className="text-center text-sm text-gray-600 mb-8">
//             {data.description}
//           </p>

//           <form className="space-y-6" onSubmit={handleSubmit}>
//             <div>
//               <label
//                 htmlFor="email"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Email address
//               </label>
//               <input
//                 id="email"
//                 type="email"
//                 required
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
//               />
//             </div>

//             <div>
//               <label
//                 htmlFor="password"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Password
//               </label>
//               <input
//                 id="password"
//                 type="password"
//                 required
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
//               />
//             </div>

//             <div className="flex items-center justify-between">
//               <div className="flex items-center">
//                 <input
//                   id="remember-me"
//                   type="checkbox"
//                   className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
//                 />
//                 <label
//                   htmlFor="remember-me"
//                   className="ml-2 block text-sm text-gray-900"
//                 >
//                   Remember me
//                 </label>
//               </div>

//               <button
//                 type="button"
//                 className="text-sm font-medium text-blue-600 hover:text-blue-500"
//               >
//                 Forgot password?
//               </button>
//             </div>

//             <button
//               type="submit"
//               disabled={isLoading}
//               className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${colorClasses[data.color]} focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`}
//             >
//               {isLoading ? (
//                 <svg
//                   className="animate-spin h-5 w-5 text-white"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                 >
//                   <circle
//                     className="opacity-25"
//                     cx="12"
//                     cy="12"
//                     r="10"
//                     stroke="currentColor"
//                     strokeWidth="4"
//                   ></circle>
//                   <path
//                     className="opacity-75"
//                     fill="currentColor"
//                     d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                   ></path>
//                 </svg>
//               ) : (
//                 <>
//                   <LogIn className="w-4 h-4 mr-2" />
//                   Sign in
//                 </>
//               )}
//             </button>
//             <p className="mt-6 text-center text-sm text-gray-600">
//               Not registered yet?{' '}
//               <button
//                 className={`font-medium text-${data.color}-600 hover:text-${data.color}-500`}
//               >
//                 Create an account
//               </button>
//             </p>
//           </form>

//           <div className="mt-6">
//             <div className="relative">
//               <div className="absolute inset-0 flex items-center">
//                 <div className="w-full border-t border-gray-300"></div>
//               </div>
//               <div className="relative flex justify-center text-sm">
//                 <span className="bg-white px-2 text-gray-500">Features</span>
//               </div>
//             </div>

//             <div className="mt-6 grid grid-cols-1 gap-2">
//               {data.features.map((feature, index) => (
//                 <div
//                   key={index}
//                   className="flex items-center px-3 py-2 text-sm text-gray-700 bg-gray-50 rounded-md"
//                 >
//                   <div
//                     className={`w-1 h-1 rounded-full bg-${data.color}-500 mr-2`}
//                   ></div>
//                   {feature}
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignInPortal;

import React, { useState } from 'react';
import {
  LogIn,
  UserCircle2,
  Building2,
  School,
  GraduationCap,
  X,
  Phone,
  ArrowRight,
} from 'lucide-react';

const serviceData = {
  talent: {
    title: 'Talent Sign In',
    description: 'Access your career dashboard and opportunities',
    icon: UserCircle2,
    color: 'teal',
    features: [
      'Track your job applications',
      'Access learning resources',
      'Connect with employers',
      'View upcoming interviews',
    ],
    subdomain: 'talent.yourdomain.com',
  },
  employer: {
    title: 'Employer Sign In',
    description: 'Manage your recruitment pipeline and connect with top talent',
    icon: Building2,
    color: 'blue',
    features: [
      'Post job openings',
      'Review applications',
      'Schedule interviews',
      'Access talent pool',
    ],
    subdomain: 'employer.yourdomain.com',
  },
  trainer: {
    title: 'Trainer Sign In',
    description: 'Manage your courses and track student progress',
    icon: School,
    color: 'purple',
    features: [
      'Manage course content',
      'Track student progress',
      'Schedule sessions',
      'Generate reports',
    ],
    subdomain: 'trainer.yourdomain.com',
  },
  university: {
    title: 'University Partner Sign In',
    description: 'Collaborate on educational programs and track partnerships',
    icon: GraduationCap,
    color: 'orange',
    features: [
      'Manage partnership programs',
      'Track student placements',
      'Access resources',
      'Generate analytics',
    ],
    subdomain: 'university.yourdomain.com',
  },
};

const SignInPortal = ({ service = 'talent', onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');

  // Default to talent if invalid service is provided
  const currentService = serviceData[service] ? service : 'talent';
  const data = serviceData[currentService];
  const IconComponent = data.icon;

  const colorClasses = {
    teal: 'bg-teal-500 hover:bg-teal-600 focus:ring-teal-500',
    blue: 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-500',
    purple: 'bg-purple-500 hover:bg-purple-600 focus:ring-purple-500',
    orange: 'bg-orange-500 hover:bg-orange-600 focus:ring-orange-500',
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Simulate API call to send OTP
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setOtpSent(true);
    setIsLoading(false);
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Simulate API call to verify OTP
    await new Promise((resolve) => setTimeout(resolve, 1000));
    // Redirect to talent subdomain
    window.location.href = `https://${data.subdomain}/dashboard`;
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Simulate API call
    await new Promise((resolve) => setTimeout(resolve, 1000));
    // Redirect to respective subdomain
    window.location.href = `https://${data.subdomain}/dashboard`;
  };

  const TalentLogin = () => (
    <form onSubmit={otpSent ? handleVerifyOTP : handleSendOTP}>
      <div className="space-y-6">
        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            Mobile Number
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <Phone className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="tel"
              id="phone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-teal-500 focus:border-teal-500"
              placeholder="+91 9999999999"
              disabled={otpSent}
              required
            />
          </div>
        </div>

        {otpSent && (
          <div>
            <label
              htmlFor="otp"
              className="block text-sm font-medium text-gray-700"
            >
              Enter OTP
            </label>
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-teal-500 focus:border-teal-500"
              placeholder="Enter 6-digit OTP"
              required
            />
          </div>
        )}

        <button
          type="submit"
          disabled={isLoading}
          className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${colorClasses[data.color]} focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`}
        >
          {isLoading ? (
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <>
              {otpSent ? 'Verify OTP' : 'Send OTP'}
              <ArrowRight className="w-4 h-4 ml-2" />
            </>
          )}
        </button>
        <p className="mt-6 text-center text-sm text-gray-600">
          Not registered yet?{' '}
          <button
            className={`font-medium text-${data.color}-600 hover:text-${data.color}-500`}
          >
            Create an account
          </button>
        </p>
      </div>
    </form>
  );

  const EmailLogin = () => (
    <form onSubmit={handleEmailLogin}>
      <div className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300"
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-700"
            >
              Remember me
            </label>
          </div>

          <button
            type="button"
            className="text-sm font-medium text-blue-600 hover:text-blue-500"
          >
            Forgot password?
          </button>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${colorClasses[data.color]} focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`}
        >
          {isLoading ? (
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <>
              <LogIn className="w-4 h-4 mr-2" />
              Sign in
            </>
          )}
        </button>
        <p className="mt-6 text-center text-sm text-gray-600">
          Not registered yet?{' '}
          <button
            className={`font-medium text-${data.color}-600 hover:text-${data.color}-500`}
          >
            Create an account
          </button>
        </p>
      </div>
    </form>
  );

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl w-full max-w-md relative overflow-y-auto max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full"
        >
          <X className="w-5 h-5 text-gray-500" />
        </button>

        <div className="p-6">
          <div className="flex items-center justify-center space-x-2 mb-6">
            <IconComponent className={`w-8 h-8 text-${data.color}-500`} />
            <h2 className="text-2xl font-bold text-gray-900">{data.title}</h2>
          </div>

          <p className="text-center text-sm text-gray-600 mb-8">
            {data.description}
          </p>

          {service === 'talent' ? <TalentLogin /> : <EmailLogin />}

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">Features</span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-2">
              {data.features.map((feature, index) => (
                <div
                  key={index}
                  className="flex items-center px-3 py-2 text-sm text-gray-700 bg-gray-50 rounded-md"
                >
                  <div
                    className={`w-1 h-1 rounded-full bg-${data.color}-500 mr-2`}
                  ></div>
                  {feature}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPortal;
