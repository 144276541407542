// import React from 'react';

// const StatsSection = () => {
//   const stats = [
//     { number: '4+', label: 'Countries Covered' },
//     { number: '100+', label: 'Successfully Placed Candidates' },
//     { number: '95%', label: 'Client Satisfaction Rate' },
//     { number: '>15 Days', label: 'To find a Candidate' },
//   ];

//   return (
//     <section className="bg-blue-600 py-16">
//       <div className="max-w-7xl mx-auto px-6">
//         <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
//           {stats.map((stat, index) => (
//             <div key={index} className="text-center">
//               <div className="text-4xl font-bold text-white mb-2">
//                 {stat.number}
//               </div>
//               <div className="text-blue-100">{stat.label}</div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default StatsSection;

import React from 'react';

const StatsSection = () => {
  const stats = [
    { number: '4+', label: 'Countries Covered' },
    { number: '100+', label: 'Successfully Placed Candidates' },
    { number: '95%', label: 'Client Satisfaction Rate' },
    { number: '>15 Days', label: 'To find a Candidate' },
  ];

  return (
    <section className="bg-blue-600 py-12 mobile:py-16">
      <div className="max-w-7xl mx-auto px-4 mobile:px-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mobile:gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-2xl mobile:text-3xl sm:text-4xl font-bold text-white mb-1 mobile:mb-2">
                {stat.number}
              </div>
              <div className="text-xs mobile:text-sm sm:text-base text-blue-100">
                {stat.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
