// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../assets/logo-highdef.png';
// import {
//   LogIn,
//   X,
//   UserCircle2,
//   Building2,
//   School,
//   GraduationCap,
// } from 'lucide-react';
// import SignInPortal from './SignInPortal';

// const Header = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [showAuthModal, setShowAuthModal] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   const handleServiceSelect = (service) => {
//     setSelectedService(service);
//     setShowAuthModal(false);
//   };

//   const AuthModal = () => (
//     <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
//       <div className="bg-white rounded-2xl w-full max-w-4xl overflow-hidden relative">
//         <button
//           onClick={() => setShowAuthModal(false)}
//           className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full"
//         >
//           <X className="w-5 h-5 text-gray-500" />
//         </button>

//         <div className="p-6">
//           <h2 className="text-2xl font-bold text-gray-900 mb-4">
//             Select Login Portal
//           </h2>
//           <p className="text-gray-600 mb-6">
//             Select the appropriate portal to access your dashboard
//           </p>

//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {/* Primary Services */}
//             <button
//               onClick={() => handleServiceSelect('talent')}
//               className="bg-teal-500 hover:opacity-90 text-white p-6 rounded-xl transition-all duration-200 transform hover:scale-[1.02] hover:shadow-lg text-left group"
//             >
//               <div className="flex flex-col h-full">
//                 <UserCircle2 className="w-8 h-8 mb-3" />
//                 <h3 className="text-lg font-semibold mb-2">Talent Portal</h3>
//                 <p className="text-sm opacity-90 mb-4">
//                   Find opportunities and manage your profile
//                 </p>
//                 <div className="mt-auto flex items-center text-sm font-medium opacity-90 group-hover:opacity-100">
//                   Login or Sign up
//                   <LogIn className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
//                 </div>
//               </div>
//             </button>

//             <button
//               onClick={() => handleServiceSelect('employer')}
//               className="bg-blue-500 hover:opacity-90 text-white p-6 rounded-xl transition-all duration-200 transform hover:scale-[1.02] hover:shadow-lg text-left group"
//             >
//               <div className="flex flex-col h-full">
//                 <Building2 className="w-8 h-8 mb-3" />
//                 <h3 className="text-lg font-semibold mb-2">Employer Portal</h3>
//                 <p className="text-sm opacity-90 mb-4">
//                   Hire talent and manage recruitment
//                 </p>
//                 <div className="mt-auto flex items-center text-sm font-medium opacity-90 group-hover:opacity-100">
//                   Login or Sign up
//                   <LogIn className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
//                 </div>
//               </div>
//             </button>

//             {/* Secondary Services */}
//             <button
//               onClick={() => handleServiceSelect('trainer')}
//               className="bg-gray-100 hover:bg-gray-200 p-4 rounded-lg transition-all duration-200 text-left group flex items-center space-x-4"
//             >
//               <School className="w-6 h-6 text-gray-500" />
//               <div>
//                 <h3 className="text-gray-900 font-medium">Trainer Portal</h3>
//                 <p className="text-sm text-gray-500">
//                   Manage courses and student progress
//                 </p>
//               </div>
//             </button>

//             <button
//               onClick={() => handleServiceSelect('university')}
//               className="bg-gray-100 hover:bg-gray-200 p-4 rounded-lg transition-all duration-200 text-left group flex items-center space-x-4"
//             >
//               <GraduationCap className="w-6 h-6 text-gray-500" />
//               <div>
//                 <h3 className="text-gray-900 font-medium">
//                   University Partner Portal
//                 </h3>
//                 <p className="text-sm text-gray-500">
//                   Collaborate on educational programs
//                 </p>
//               </div>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   return (
//     <>
//       <header className="fixed top-0 left-0 w-full z-40 px-4">
//         <div className="container mx-auto flex justify-between items-center py-4">
//           {/* Logo */}
//           <Link to="/" className="relative z-50">
//             <img src={logo} alt="TalentFirst Logo" className="h-10 w-auto" />
//           </Link>

//           {/* Centered Navigation */}
//           <nav className="hidden md:block">
//             <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-white/80 backdrop-blur-lg rounded-full px-8 py-4 shadow-lg">
//               <div className="flex space-x-8">
//                 <Link
//                   to="/"
//                   className="text-custom-blue hover:text-teal-500 font-medium transition-colors"
//                 >
//                   Home
//                 </Link>
//                 <Link
//                   to="/talent"
//                   className="text-custom-blue hover:text-teal-500 font-medium transition-colors"
//                 >
//                   Talent
//                 </Link>
//                 <Link
//                   to="/employer"
//                   className="text-custom-blue hover:text-teal-500 font-medium transition-colors"
//                 >
//                   Employer
//                 </Link>
//                 <Link
//                   to="/contact"
//                   className="text-custom-blue hover:text-teal-500 font-medium transition-colors"
//                 >
//                   Contact
//                 </Link>
//               </div>
//             </div>
//           </nav>

//           {/* Auth Button */}
//           <button
//             onClick={() => setShowAuthModal(true)}
//             className="hidden md:flex items-center space-x-2 bg-teal-500 text-white px-4 py-2 rounded-full hover:bg-teal-600 transition-all duration-200 transform hover:scale-105"
//           >
//             <LogIn className="w-4 h-4" />
//             <span>Access Portal</span>
//           </button>

//           {/* Mobile Menu Button */}
//           <button
//             onClick={toggleMenu}
//             className="md:hidden relative z-50 p-2 rounded-lg hover:bg-gray-100"
//           >
//             <svg
//               className="w-6 h-6 text-custom-blue"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
//               />
//             </svg>
//           </button>
//         </div>

//         {/* Mobile Navigation */}
//         {menuOpen && (
//           <div className="md:hidden fixed inset-0 bg-white/95 backdrop-blur-sm z-40">
//             <nav className="h-full flex flex-col items-center justify-center">
//               <ul className="flex flex-col items-center space-y-8 mb-8">
//                 <li>
//                   <Link
//                     to="/"
//                     onClick={toggleMenu}
//                     className="text-xl text-custom-blue hover:text-teal-500 font-medium"
//                   >
//                     Home
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/talent"
//                     onClick={toggleMenu}
//                     className="text-xl text-custom-blue hover:text-teal-500 font-medium"
//                   >
//                     Talent
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/employer"
//                     onClick={toggleMenu}
//                     className="text-xl text-custom-blue hover:text-teal-500 font-medium"
//                   >
//                     Employer
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/contact"
//                     onClick={toggleMenu}
//                     className="text-xl text-custom-blue hover:text-teal-500 font-medium"
//                   >
//                     Contact
//                   </Link>
//                 </li>
//                 <li>
//                   <button
//                     onClick={() => {
//                       setShowAuthModal(true);
//                       setMenuOpen(false);
//                     }}
//                     className="flex items-center space-x-2 bg-teal-500 text-white px-6 py-3 rounded-full hover:bg-teal-600 transition-colors"
//                   >
//                     <LogIn className="w-4 h-4" />
//                     <span>Access Portal</span>
//                   </button>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         )}
//       </header>

//       {/* Auth Modal */}
//       {showAuthModal && <AuthModal />}

//       {/* Sign In Portal */}
//       {selectedService && (
//         <SignInPortal
//           service={selectedService}
//           onClose={() => setSelectedService(null)}
//         />
//       )}
//     </>
//   );
// };

// export default Header;

// // This completed header component includes:
// // 1. Responsive navigation
// // 2. Glass-effect centered nav menu
// // 3. Mobile menu with animations
// // 4. Portal access modal
// // 5. Sign-in integration
// // 6. Banner integration
// // 7. Proper z-index layering

// // Would you like me to modify any specific part of it?

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  LogIn,
  X,
  UserCircle2,
  Building2,
  School,
  GraduationCap,
} from 'lucide-react';
import SignInPortal from './SignInPortal';
import logo from '../assets/logo-highdef.png';

const ServiceButton = ({
  icon: Icon,
  title,
  description,
  onClick,
  variant = 'primary',
}) => (
  <button
    onClick={onClick}
    className={`${
      variant === 'primary'
        ? 'bg-teal-500 hover:opacity-90 text-white'
        : 'bg-gray-100 hover:bg-gray-200'
    } p-4 sm:p-6 rounded-xl transition-all duration-200 text-left group w-full`}
  >
    <div className="flex flex-col h-full">
      <Icon
        className={`w-6 h-6 sm:w-8 sm:h-8 mb-3 ${variant === 'secondary' ? 'text-gray-500' : ''}`}
      />
      <h3
        className={`text-base sm:text-lg font-semibold mb-2 ${variant === 'secondary' ? 'text-gray-900' : ''}`}
      >
        {title}
      </h3>
      <p
        className={`text-xs sm:text-sm ${variant === 'secondary' ? 'text-gray-500' : 'opacity-90'} mb-4`}
      >
        {description}
      </p>
      {variant === 'primary' && (
        <div className="mt-auto flex items-center text-xs sm:text-sm font-medium opacity-90 group-hover:opacity-100">
          Login or Sign up
          <LogIn className="w-3 h-3 sm:w-4 sm:h-4 ml-2 transition-transform group-hover:translate-x-1" />
        </div>
      )}
    </div>
  </button>
);

const AuthModal = ({ onClose, onServiceSelect }) => (
  <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-2xl w-full max-w-4xl overflow-hidden relative">
      <button
        onClick={onClose}
        className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
      >
        <X className="w-5 h-5 text-gray-500" />
      </button>

      <div className="p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2 sm:mb-4">
          Select Login Portal
        </h2>
        <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
          Select the appropriate portal to access your dashboard
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <ServiceButton
            icon={UserCircle2}
            title="Talent Portal"
            description="Find opportunities and manage your profile"
            onClick={() => onServiceSelect('talent')}
          />
          <ServiceButton
            icon={Building2}
            title="Employer Portal"
            description="Hire talent and manage recruitment"
            onClick={() => onServiceSelect('employer')}
          />
          <ServiceButton
            icon={School}
            title="Trainer Portal"
            description="Manage courses and student progress"
            onClick={() => onServiceSelect('trainer')}
            variant="secondary"
          />
          <ServiceButton
            icon={GraduationCap}
            title="University Partner Portal"
            description="Collaborate on educational programs"
            onClick={() => onServiceSelect('university')}
            variant="secondary"
          />
        </div>
      </div>
    </div>
  </div>
);

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const navLinks = [
    { path: '/', label: 'Home' },
    { path: '/talent', label: 'Talent' },
    { path: '/employer', label: 'Employer' },
    { path: '/contact', label: 'Contact' },
  ];

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    setShowAuthModal(false);
  };

  return (
    <header className="fixed top-0 left-0 w-full z-40">
      <div className="mx-4 2xl:container 2xl:mx-auto">
        <div className="flex justify-between items-center py-4 max-w-[2000px] mx-auto">
          {/* Logo */}
          <div className="pl-0 md:pl-4 lg:pl-6 xl:pl-8 2xl:pl-12">
            <Link to="/" className="relative z-50 block">
              <img
                src={logo}
                alt="TalentFirst Logo"
                className="h-8 sm:h-10 w-auto"
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:block absolute left-1/2 transform -translate-x-1/2">
            <div className="bg-white/80 backdrop-blur-lg rounded-full px-6 sm:px-8 py-3 sm:py-4 shadow-lg">
              <div className="flex space-x-6 sm:space-x-8">
                {navLinks.map((link) => (
                  <Link
                    key={link.path}
                    to={link.path}
                    className="text-sm sm:text-base text-custom-blue hover:text-teal-500 
                             font-medium transition-colors"
                  >
                    {link.label}
                  </Link>
                ))}
              </div>
            </div>
          </nav>

          {/* Auth Button & Mobile Menu */}
          <div className="pr-0 md:pr-4 lg:pr-6 xl:pr-8 2xl:pr-12">
            <button
              onClick={() => setShowAuthModal(true)}
              className="hidden md:flex items-center space-x-2 bg-teal-500 text-white 
                       px-3 sm:px-4 py-2 rounded-full hover:bg-teal-600 
                       transition-all duration-200 transform hover:scale-105 text-sm sm:text-base"
            >
              <LogIn className="w-3 h-3 sm:w-4 sm:h-4" />
              <span>Access Portal</span>
            </button>

            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="md:hidden relative z-50 p-2 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <svg
                className="w-5 h-5 sm:w-6 sm:h-6 text-custom-blue"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {menuOpen && (
        <div className="md:hidden fixed inset-0 bg-white/95 backdrop-blur-sm z-40">
          <nav className="h-full flex flex-col items-center justify-center">
            <ul className="flex flex-col items-center space-y-6 sm:space-y-8 mb-8">
              {navLinks.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    onClick={() => setMenuOpen(false)}
                    className="text-lg sm:text-xl text-custom-blue hover:text-teal-500 
                             font-medium transition-colors"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
              <li>
                <button
                  onClick={() => {
                    setShowAuthModal(true);
                    setMenuOpen(false);
                  }}
                  className="flex items-center space-x-2 bg-teal-500 text-white 
                           px-5 sm:px-6 py-2.5 sm:py-3 rounded-full hover:bg-teal-600 
                           transition-colors text-sm sm:text-base"
                >
                  <LogIn className="w-3 h-3 sm:w-4 sm:h-4" />
                  <span>Access Portal</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {/* Modals */}
      {showAuthModal && (
        <AuthModal
          onClose={() => setShowAuthModal(false)}
          onServiceSelect={handleServiceSelect}
        />
      )}

      {selectedService && (
        <SignInPortal
          service={selectedService}
          onClose={() => setSelectedService(null)}
        />
      )}
    </header>
  );
};

export default Header;
