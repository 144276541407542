// import React from 'react';
// import { Globe, Users, ClipboardCheck, ArrowRight } from 'lucide-react';

// const FloatingDrop = ({ size, duration, delay, left, top }) => (
//   <div
//     className="absolute rounded-full mix-blend-multiply animate-float pointer-events-none"
//     style={{
//       width: size,
//       height: size,
//       left: `${left}%`,
//       top: `${top}%`,
//       background:
//         'linear-gradient(45deg, rgba(59, 130, 246, 0.05), rgba(37, 99, 235, 0.08))',
//       animation: `float ${duration}s ease-in-out infinite`,
//       animationDelay: `${delay}s`,
//     }}
//   />
// );

// const ModernEnterprise = () => {
//   const steps = [
//     {
//       title: 'Global Talent Sourcing',
//       description:
//         'Access our extensive network of pre-vetted global talent across temporary and permanent positions. We handle candidate discovery, screening, and initial assessments.',
//       icon: <Globe className="w-6 h-6 text-blue-600" />,
//       metrics: ['200+ countries', '48hr sourcing'],
//     },
//     {
//       title: 'Compliance & Documentation',
//       description:
//         'End-to-end compliance management including work permits, visas, and local regulations. We ensure all hiring processes meet international standards.',
//       icon: <ClipboardCheck className="w-6 h-6 text-blue-600" />,
//       metrics: ['100% compliance', 'Visa support'],
//     },
//     {
//       title: 'Integration & Onboarding',
//       description:
//         'Seamless onboarding with cultural integration support, payroll setup, and complete documentation handling for both remote and relocating talent.',
//       icon: <Users className="w-6 h-6 text-blue-600" />,
//       metrics: ['15-day setup', 'Full support'],
//     },
//   ];

//   const drops = [
//     { size: 120, duration: 20, delay: 0, left: 10, top: 20 },
//     { size: 140, duration: 23, delay: 2, left: 30, top: 10 },
//     { size: 100, duration: 18, delay: 1, left: 50, top: 30 },
//     { size: 130, duration: 25, delay: 3, left: 70, top: 15 },
//     { size: 110, duration: 22, delay: 2, left: 90, top: 25 },
//   ];

//   return (
//     <div className="relative min-h-screen">
//       <style>
//         {`
//           @keyframes float {
//             0%, 100% { transform: translate(0, 0); }
//             50% { transform: translate(-10px, -15px); }
//           }
//         `}
//       </style>

//       <section className="bg-gradient-to-b from-blue-50/20 to-white py-16 relative overflow-hidden">
//         {drops.map((drop, index) => (
//           <FloatingDrop key={index} {...drop} />
//         ))}

//         <div className="max-w-5xl mx-auto px-6 relative">
//           <div className="text-center mb-16">
//             <div className="max-w-7xl mx-auto px-6">
//               <div className="text-center">
//                 <span className="bg-blue-100 text-blue-800 px-4 py-1 rounded-full text-sm font-medium">
//                   Global Talent Solutions
//                 </span>
//                 <h1 className="mt-6 text-4xl font-bold text-gray-900 sm:text-5xl">
//                   Simplifying Global Hiring
//                 </h1>
//               </div>
//             </div>
//             <p className="text-lg text-gray-600 max-w-2xl mx-auto">
//               Your single-window solution for hiring and managing global talent,
//               with complete compliance and integration support.
//             </p>
//           </div>

//           <div className="grid gap-8">
//             {steps.map((step, index) => (
//               <div
//                 key={index}
//                 className="group bg-white/90 backdrop-blur-sm rounded-xl border border-gray-200/50 shadow-sm p-8 hover:shadow-lg transition-all duration-300 relative overflow-hidden"
//               >
//                 <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-blue-100 to-blue-50 rounded-bl-full opacity-20 transform group-hover:scale-110 transition-transform duration-300" />

//                 <div className="flex items-start gap-6 relative">
//                   <div className="flex-shrink-0 w-14 h-14 bg-gradient-to-br from-blue-100 to-blue-50 rounded-lg flex items-center justify-center transform group-hover:rotate-6 transition-transform duration-300">
//                     {step.icon}
//                   </div>

//                   <div className="flex-grow">
//                     <div className="flex items-center gap-3 mb-2">
//                       <span className="text-blue-600 font-semibold text-sm">
//                         PHASE {index + 1}
//                       </span>
//                       <ArrowRight className="w-4 h-4 text-blue-400" />
//                     </div>

//                     <h3 className="text-xl font-semibold text-gray-900 mb-3">
//                       {step.title}
//                     </h3>

//                     <p className="text-gray-600 mb-4">{step.description}</p>

//                     <div className="flex gap-6">
//                       {step.metrics.map((metric, idx) => (
//                         <div
//                           key={idx}
//                           className="px-4 py-1.5 bg-blue-50/50 rounded-full text-sm text-blue-700 font-medium group-hover:bg-blue-100/50 transition-colors duration-300"
//                         >
//                           {metric}
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default ModernEnterprise;

import React from 'react';
import { Globe, Users, ClipboardCheck, ArrowRight } from 'lucide-react';

const FloatingDrop = ({ size, duration, delay, left, top }) => (
  <div
    className="absolute rounded-full mix-blend-multiply animate-float pointer-events-none"
    style={{
      width: size,
      height: size,
      left: `${left}%`,
      top: `${top}%`,
      background:
        'linear-gradient(45deg, rgba(59, 130, 246, 0.05), rgba(37, 99, 235, 0.08))',
      animation: `float ${duration}s ease-in-out infinite`,
      animationDelay: `${delay}s`,
    }}
  />
);

const ModernEnterprise = () => {
  const steps = [
    {
      title: 'Global Talent Sourcing',
      description:
        'Access our extensive network of pre-vetted global talent across temporary and permanent positions. We handle candidate discovery, screening, and initial assessments.',
      icon: <Globe className="w-6 h-6 text-blue-600" />,
      metrics: ['200+ countries', '48hr sourcing'],
    },
    {
      title: 'Compliance & Documentation',
      description:
        'End-to-end compliance management including work permits, visas, and local regulations. We ensure all hiring processes meet international standards.',
      icon: <ClipboardCheck className="w-6 h-6 text-blue-600" />,
      metrics: ['100% compliance', 'Visa support'],
    },
    {
      title: 'Integration & Onboarding',
      description:
        'Seamless onboarding with cultural integration support, payroll setup, and complete documentation handling for both remote and relocating talent.',
      icon: <Users className="w-6 h-6 text-blue-600" />,
      metrics: ['15-day setup', 'Full support'],
    },
  ];

  const drops = [
    { size: 120, duration: 20, delay: 0, left: 10, top: 20 },
    { size: 140, duration: 23, delay: 2, left: 30, top: 10 },
    { size: 100, duration: 18, delay: 1, left: 50, top: 30 },
    { size: 130, duration: 25, delay: 3, left: 70, top: 15 },
    { size: 110, duration: 22, delay: 2, left: 90, top: 25 },
  ];

  return (
    <div className="relative min-h-screen">
      <style>
        {`
          @keyframes float {
            0%, 100% { transform: translate(0, 0); }
            50% { transform: translate(-10px, -15px); }
          }
        `}
      </style>

      <section className="bg-gradient-to-b from-blue-50/20 to-white py-16 relative overflow-hidden">
        {drops.map((drop, index) => (
          <FloatingDrop key={index} {...drop} />
        ))}

        <div className="max-w-5xl mx-auto px-6 relative">
          <div className="text-center mb-16">
            <div className="max-w-7xl mx-auto px-6">
              <div className="text-center">
                <span className="bg-blue-100 text-blue-800 px-4 py-1 rounded-full text-xs mobile:text-sm font-medium">
                  Global Talent Solutions
                </span>
                <h1 className="mt-6 text-2xl mobile:text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-900">
                  Simplifying Global Hiring
                </h1>
              </div>
            </div>
            <p className="text-sm mobile:text-base sm:text-lg text-gray-600 max-w-2xl mx-auto">
              Your single-window solution for hiring and managing global talent,
              with complete compliance and integration support.
            </p>
          </div>

          <div className="grid gap-8">
            {steps.map((step, index) => (
              <div
                key={index}
                className="group bg-white/90 backdrop-blur-sm rounded-xl border border-gray-200/50 shadow-sm p-8 hover:shadow-lg transition-all duration-300 relative overflow-hidden"
              >
                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-blue-100 to-blue-50 rounded-bl-full opacity-20 transform group-hover:scale-110 transition-transform duration-300" />

                <div className="flex items-start gap-6 relative">
                  <div className="flex-shrink-0 w-14 h-14 bg-gradient-to-br from-blue-100 to-blue-50 rounded-lg flex items-center justify-center transform group-hover:rotate-6 transition-transform duration-300">
                    {step.icon}
                  </div>

                  <div className="flex-grow">
                    <div className="flex items-center gap-3 mb-2">
                      <span className="text-xs mobile:text-sm text-blue-600 font-semibold">
                        PHASE {index + 1}
                      </span>
                      <ArrowRight className="w-4 h-4 text-blue-400" />
                    </div>

                    <h3 className="text-lg mobile:text-xl font-semibold text-gray-900 mb-3">
                      {step.title}
                    </h3>

                    <p className="text-xs mobile:text-sm text-gray-600 mb-4">
                      {step.description}
                    </p>

                    <div className="flex gap-6">
                      {step.metrics.map((metric, idx) => (
                        <div
                          key={idx}
                          className="px-4 py-1.5 bg-blue-50/50 rounded-full text-[10px] mobile:text-xs sm:text-sm text-blue-700 font-medium group-hover:bg-blue-100/50 transition-colors duration-300"
                        >
                          {metric}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ModernEnterprise;
