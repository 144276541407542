// import React, { useState } from 'react';
// import { Sparkles, Rocket, Target, Award } from 'lucide-react';

// const GlobalProcess = () => {
//   const [activeStep, setActiveStep] = useState(null);

//   const themes = {
//     ocean: {
//       bg: 'bg-gradient-to-br from-cyan-50 via-blue-50 to-teal-50',
//       primary: 'from-cyan-600 to-blue-600',
//       secondary: 'from-cyan-200 via-blue-200 to-teal-200',
//       hover: 'border-cyan-400 shadow-cyan-100',
//       iconBg: 'from-cyan-100 to-blue-100',
//       iconColor: 'text-cyan-600',
//       highlight: 'text-cyan-600',
//     },
//   };

//   const steps = [
//     {
//       icon: Target,
//       title: 'Dream Big',
//       description:
//         'Take the first step towards your international career adventure',
//       timeline: 'Week 1',
//       highlight:
//         'Get matched with a personal career advisor who will guide your journey',
//     },
//     {
//       icon: Rocket,
//       title: 'Launch Your Skills',
//       description: 'Level up with cutting-edge training from industry experts',
//       timeline: 'Weeks 2-5',
//       highlight:
//         'Master new technologies and gain globally recognized certifications',
//     },
//     {
//       icon: Sparkles,
//       title: 'Shine Globally',
//       description: 'Connect with top companies seeking your unique talents',
//       timeline: 'Weeks 6-7',
//       highlight: 'Interview with companies that match your dream career path',
//     },
//     {
//       icon: Award,
//       title: 'Achievement Unlocked',
//       description: 'Begin your exciting new chapter abroad',
//       timeline: 'Weeks 8-12',
//       highlight:
//         'Full support with visas, housing, and settling into your new home',
//     },
//   ];

//   return (
//     <section className={`min-h-[700px] ${themes.ocean.bg} flex items-center`}>
//       <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//         <div className="text-center max-w-3xl mx-auto mb-12">
//           <h2
//             className={`text-4xl lg:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r ${themes.ocean.primary} mb-4`}
//           >
//             Your Global Adventure Starts Here
//           </h2>
//           <p className="text-xl text-gray-600">
//             Transform your career journey into an exciting global adventure
//           </p>
//         </div>

//         <div className="relative">
//           <div
//             className={`absolute top-1/2 left-0 right-0 h-1 bg-gradient-to-r ${themes.ocean.secondary} -translate-y-1/2 hidden lg:block`}
//           />

//           <div className="grid lg:grid-cols-4 gap-8">
//             {steps.map((step, index) => {
//               const Icon = step.icon;
//               const isActive = activeStep === index;

//               return (
//                 <div
//                   key={index}
//                   className="relative lg:hover:scale-110 transform transition-all duration-300 h-full"
//                   onMouseEnter={() => setActiveStep(index)}
//                   onMouseLeave={() => setActiveStep(null)}
//                 >
//                   <div
//                     className={`bg-white rounded-xl p-6 border-2 transition-all duration-300 h-full ${
//                       isActive ? themes.ocean.hover : 'border-gray-200'
//                     }`}
//                   >
//                     <div
//                       className={`absolute -top-3 left-1/2 -translate-x-1/2 bg-gradient-to-r ${themes.ocean.primary} text-white px-4 py-1 rounded-full text-sm font-medium`}
//                     >
//                       {step.timeline}
//                     </div>

//                     <div className="text-center mt-4">
//                       <div
//                         className={`inline-flex p-4 rounded-full transition-all duration-300 ${
//                           isActive
//                             ? `bg-gradient-to-r ${themes.ocean.iconBg}`
//                             : 'bg-gray-100'
//                         }`}
//                       >
//                         <Icon
//                           className={`w-8 h-8 transition-all duration-300 ${
//                             isActive ? themes.ocean.iconColor : 'text-gray-700'
//                           }`}
//                         />
//                       </div>

//                       <h3 className="text-xl font-bold text-gray-900 mb-2 mt-4">
//                         {step.title}
//                       </h3>
//                       <p className="text-gray-600">{step.description}</p>

//                       <div
//                         className={`mt-4 transition-all duration-300 overflow-hidden ${
//                           isActive
//                             ? 'max-h-24 opacity-100'
//                             : 'max-h-0 opacity-0'
//                         }`}
//                       >
//                         <p
//                           className={`text-sm italic ${themes.ocean.highlight}`}
//                         >
//                           ✨ {step.highlight}
//                         </p>
//                       </div>
//                     </div>
//                   </div>

//                   {index < steps.length - 1 && (
//                     <div
//                       className={`hidden lg:block absolute top-1/2 right-0 w-1/2 h-0.5 bg-gradient-to-r ${themes.ocean.secondary} -translate-y-1/2`}
//                     />
//                   )}
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default GlobalProcess;

import React, { useState } from 'react';
import { Sparkles, Rocket, Target, Award } from 'lucide-react';

const GlobalProcess = () => {
  const [activeStep, setActiveStep] = useState(null);

  const themes = {
    ocean: {
      bg: 'bg-gradient-to-br from-cyan-50 via-blue-50 to-teal-50',
      primary: 'from-cyan-600 to-blue-600',
      secondary: 'from-cyan-200 via-blue-200 to-teal-200',
      hover: 'border-cyan-400 shadow-cyan-100',
      iconBg: 'from-cyan-100 to-blue-100',
      iconColor: 'text-cyan-600',
      highlight: 'text-cyan-600',
    },
  };

  const steps = [
    {
      icon: Target,
      title: 'Dream Big',
      description:
        'Take the first step towards your international career adventure',
      timeline: 'Week 1',
      highlight:
        'Get matched with a personal career advisor who will guide your journey',
    },
    {
      icon: Rocket,
      title: 'Launch Your Skills',
      description: 'Level up with cutting-edge training from industry experts',
      timeline: 'Weeks 2-5',
      highlight:
        'Master new technologies and gain globally recognized certifications',
    },
    {
      icon: Sparkles,
      title: 'Shine Globally',
      description: 'Connect with top companies seeking your unique talents',
      timeline: 'Weeks 6-7',
      highlight: 'Interview with companies that match your dream career path',
    },
    {
      icon: Award,
      title: 'Achievement Unlocked',
      description: 'Begin your exciting new chapter abroad',
      timeline: 'Weeks 8-12',
      highlight:
        'Full support with visas, housing, and settling into your new home',
    },
  ];

  return (
    <section
      className={`min-h-[500px] sm:min-h-[600px] lg:min-h-[700px] ${themes.ocean.bg} 
                        flex items-center py-8 sm:py-12 lg:py-16`}
    >
      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center max-w-3xl mx-auto mb-8 sm:mb-12">
          <h2
            className={`text-2xl sm:text-3xl lg:text-4xl xl:text-5xl font-bold text-transparent 
                         bg-clip-text bg-gradient-to-r ${themes.ocean.primary} 
                         mb-3 sm:mb-4 tracking-tight`}
          >
            Your Global Adventure Starts Here
          </h2>
          <p className="text-base sm:text-lg lg:text-xl text-gray-600 max-w-2xl mx-auto">
            Transform your career journey into an exciting global adventure
          </p>
        </div>

        {/* Timeline Section */}
        <div className="relative">
          {/* Timeline line - visible only on large screens */}
          <div
            className={`absolute top-1/2 left-0 right-0 h-0.5 bg-gradient-to-r 
                          ${themes.ocean.secondary} -translate-y-1/2 hidden lg:block`}
          />

          {/* Steps Grid */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 lg:gap-8">
            {steps.map((step, index) => {
              const Icon = step.icon;
              const isActive = activeStep === index;

              return (
                <div
                  key={index}
                  className="relative group lg:hover:scale-105 transform transition-all duration-300 h-full"
                  onMouseEnter={() => setActiveStep(index)}
                  onMouseLeave={() => setActiveStep(null)}
                >
                  {/* Card */}
                  <div
                    className={`bg-white rounded-lg sm:rounded-xl p-4 sm:p-5 lg:p-6 border-2 
                                 transition-all duration-300 h-full relative 
                                 ${isActive ? `${themes.ocean.hover} shadow-lg` : 'border-gray-200'}`}
                  >
                    {/* Timeline Label */}
                    <div
                      className={`absolute -top-2.5 left-1/2 -translate-x-1/2 bg-gradient-to-r 
                                   ${themes.ocean.primary} text-white px-3 py-0.5 sm:px-4 sm:py-1 
                                   rounded-full text-xs sm:text-sm font-medium whitespace-nowrap`}
                    >
                      {step.timeline}
                    </div>

                    {/* Content */}
                    <div className="text-center mt-4">
                      {/* Icon */}
                      <div
                        className={`inline-flex p-3 sm:p-4 rounded-full transition-all duration-300 
                                    ${isActive ? `bg-gradient-to-r ${themes.ocean.iconBg}` : 'bg-gray-100'}`}
                      >
                        <Icon
                          className={`w-6 h-6 sm:w-7 sm:h-7 lg:w-8 lg:h-8 transition-all duration-300 
                                       ${isActive ? themes.ocean.iconColor : 'text-gray-700'}`}
                        />
                      </div>

                      {/* Title and Description */}
                      <h3
                        className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-900 
                                   mb-1.5 sm:mb-2 mt-3 sm:mt-4"
                      >
                        {step.title}
                      </h3>
                      <p className="text-sm sm:text-base text-gray-600">
                        {step.description}
                      </p>

                      {/* Highlight Text - Shows on hover/active */}
                      <div
                        className={`mt-3 sm:mt-4 transition-all duration-300 overflow-hidden
                                    ${isActive ? 'max-h-24 opacity-100' : 'max-h-0 opacity-0'}`}
                      >
                        <p
                          className={`text-xs sm:text-sm italic ${themes.ocean.highlight}`}
                        >
                          ✨ {step.highlight}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Connector line between cards - visible only on large screens */}
                  {index < steps.length - 1 && (
                    <div
                      className={`hidden lg:block absolute top-1/2 right-0 w-1/2 h-0.5 
                                   bg-gradient-to-r ${themes.ocean.secondary} -translate-y-1/2`}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlobalProcess;
