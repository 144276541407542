// import React from 'react';

// const EmployerHero = ({ googleFormLink }) => {
//   return (
//     <section className="relative h-auto overflow-hidden bg-white">
//       {/* Abstract background pattern - inspired by modern SaaS designs */}
//       <div className="absolute inset-0 max-w-7xl mx-auto" aria-hidden="true">
//         <div className="absolute right-0 top-0 -translate-y-12 opacity-10">
//           <svg width="350" height="350" viewBox="0 0 200 200">
//             <defs>
//               <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
//                 <stop offset="0%" stopColor="#2563eb" />
//                 <stop offset="100%" stopColor="#4f46e5" />
//               </linearGradient>
//             </defs>
//             <path
//               fill="url(#gradient)"
//               d="M45,-57.2C54.8,-46.3,56.3,-29,59.1,-11.9C62,5.3,66.2,22.2,60.7,35.3C55.2,48.4,39.9,57.6,23.4,62.5C6.9,67.3,-10.8,67.8,-27.2,62.4C-43.6,57,-58.7,45.7,-65.4,30.5C-72.1,15.3,-70.4,-3.7,-63.3,-19.3C-56.2,-34.9,-43.7,-47,-29.8,-57.3C-15.9,-67.6,-0.7,-76.1,9.1,-71.9C18.9,-67.7,35.2,-68.1,45,-57.2Z"
//               transform="translate(100 100)"
//             />
//           </svg>
//         </div>
//         <div className="absolute left-0 bottom-0 translate-y-12 opacity-10">
//           <svg width="350" height="350" viewBox="0 0 200 200">
//             <path
//               fill="url(#gradient)"
//               d="M42.8,-65.1C54.9,-56.3,63.5,-43.3,69.4,-29C75.3,-14.7,78.6,0.8,75.6,15.2C72.6,29.6,63.3,42.8,51.1,52.3C38.9,61.8,23.8,67.5,8.2,69.6C-7.4,71.7,-23.5,70.1,-38.1,63.5C-52.7,56.9,-65.8,45.3,-73.7,30.6C-81.5,15.9,-84,-1.9,-79.1,-17.4C-74.2,-32.9,-61.9,-46,-47.8,-54.8C-33.7,-63.5,-17.8,-67.9,-1.3,-66C15.2,-64.1,30.7,-73.9,42.8,-65.1Z"
//               transform="translate(100 100)"
//             />
//           </svg>
//         </div>
//       </div>

//       <div className="relative container mx-auto px-4 pt-20 pb-24 lg:pt-52 lg:pb-40">
//         <div className="max-w-7xl mx-auto">
//           {/* Main content grid */}
//           <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
//             {/* Left column - Main content */}
//             <div className="max-w-xl lg:max-w-none">
//               {/* Enterprise badge */}
//               <div className="inline-flex items-center mb-8 bg-gradient-to-r from-gray-50 to-white border border-gray-100 rounded-full px-3 py-1 shadow-sm">
//                 <span className="flex h-2 w-2 rounded-full bg-gradient-to-r from-blue-600 to-indigo-600" />
//                 <span className="ml-3 text-sm font-medium bg-gradient-to-r from-gray-800 to-gray-600 bg-clip-text text-transparent">
//                   Enterprise Solutions
//                 </span>
//               </div>

//               {/* Main heading with gradient */}
//               <h1 className="text-4xl lg:text-5xl xl:text-6xl md:flex md:flex-row font-semibold tracking-tight mb-6">
//                 <span className="block text-gray-900">Build Your Elite</span>
//                 <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
//                   Team
//                 </span>
//               </h1>

//               <p className="text-xl text-gray-600 leading-relaxed mb-8 lg:mb-12">
//                 Access a curated network of top-tier tech talent, pre-vetted for
//                 enterprise standards. Scale your team with confidence.
//               </p>

//               {/* CTA section */}
//               <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
//                 <a
//                   href={googleFormLink}
//                   className="inline-flex items-center px-6 py-3 rounded-lg bg-gray-900 text-white font-medium
//                            shadow-sm hover:bg-gray-800 transition-colors"
//                 >
//                   Start Hiring
//                 </a>
//                 <div className="flex items-center text-gray-500 text-sm font-medium">
//                   <svg
//                     viewBox="0 0 24 24"
//                     className="w-5 h-5 mr-2 text-blue-600"
//                     fill="currentColor"
//                   >
//                     <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8.41l2.54 2.53a1 1 0 0 1-1.42 1.42L11.3 12.7A1 1 0 0 1 11 12V8a1 1 0 0 1 2 0v3.59z" />
//                   </svg>
//                   48-hour average response time
//                 </div>
//               </div>
//             </div>

//             {/* Right column - Stats grid */}
//             <div className="grid grid-cols-2 gap-4 lg:gap-6">
//               {[
//                 {
//                   metric: '95%',
//                   label: 'Success Rate',
//                   sublabel: 'Post 6-month review',
//                 },
//                 {
//                   metric: '48h',
//                   label: 'Response Time',
//                   sublabel: 'Average first contact',
//                 },
//                 {
//                   metric: '10+',
//                   label: 'Enterprise Clients',
//                   sublabel: 'Globally served',
//                 },
//                 {
//                   metric: '100+',
//                   label: 'Placements',
//                   sublabel: 'Successfully matched',
//                 },
//               ].map((stat, i) => (
//                 <div
//                   key={i}
//                   className="relative group bg-white rounded-xl border border-gray-100 p-6 shadow-sm
//                            hover:shadow-md hover:border-gray-200 transition-all duration-200"
//                 >
//                   <div className="flex flex-col">
//                     <div className="text-2xl lg:text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
//                       {stat.metric}
//                     </div>
//                     <div className="mt-2 text-sm font-medium text-gray-900">
//                       {stat.label}
//                     </div>
//                     <div className="mt-1 text-xs text-gray-500">
//                       {stat.sublabel}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default EmployerHero;

// import React from 'react';

// const EmployerHero = ({ googleFormLink }) => {
//   return (
//     <section className="relative overflow-hidden bg-white pt-16 pb-24 lg:pt-20 lg:pb-24">
//       {/* Grid background to match TalentHero */}
//       <div
//         className="absolute inset-0 opacity-30"
//         aria-hidden="true"
//         style={{
//           backgroundSize: '40px 40px',
//           backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px),
//                            linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px)`,
//           maskImage: 'linear-gradient(to right, black 60%, transparent)',
//         }}
//       />

//       {/* Abstract background pattern */}
//       <div className="absolute inset-0 max-w-7xl mx-auto" aria-hidden="true">
//         <div className="absolute right-0 top-0 -translate-y-12 opacity-10">
//           <svg width="350" height="350" viewBox="0 0 200 200">
//             <defs>
//               <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
//                 <stop offset="0%" stopColor="#2563eb" />
//                 <stop offset="100%" stopColor="#4f46e5" />
//               </linearGradient>
//             </defs>
//             <path
//               fill="url(#gradient)"
//               d="M45,-57.2C54.8,-46.3,56.3,-29,59.1,-11.9C62,5.3,66.2,22.2,60.7,35.3C55.2,48.4,39.9,57.6,23.4,62.5C6.9,67.3,-10.8,67.8,-27.2,62.4C-43.6,57,-58.7,45.7,-65.4,30.5C-72.1,15.3,-70.4,-3.7,-63.3,-19.3C-56.2,-34.9,-43.7,-47,-29.8,-57.3C-15.9,-67.6,-0.7,-76.1,9.1,-71.9C18.9,-67.7,35.2,-68.1,45,-57.2Z"
//               transform="translate(100 100)"
//             />
//           </svg>
//         </div>
//       </div>

//       <div className="relative container mx-auto px-4">
//         <div className="max-w-7xl mx-auto">
//           <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center min-h-[600px] lg:min-h-[700px]">
//             {/* Left column - Main content */}
//             <div className="max-w-xl lg:max-w-none">
//               {/* Enterprise badge */}
//               <div className="inline-flex items-center rounded-full bg-blue-50 px-3 py-1 mb-8 shadow-sm">
//                 <div className="h-2 w-2 rounded-full bg-blue-600 mr-2" />
//                 <span className="text-sm font-medium text-blue-900">
//                   Enterprise Solutions
//                 </span>
//               </div>

//               {/* Main heading with gradient */}
//               <h1 className="text-4xl lg:text-5xl xl:text-6xl font-semibold tracking-tight mb-6">
//                 Build Your Elite
//                 <span className="block mt-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
//                   Team
//                 </span>
//               </h1>

//               <p className="text-xl text-gray-600 leading-relaxed mb-8 lg:mb-12">
//                 Access a curated network of top-tier tech talent, pre-vetted for
//                 enterprise standards. Scale your team with confidence.
//               </p>

//               {/* CTA section */}
//               <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
//                 <a
//                   href={googleFormLink}
//                   className="group inline-flex items-center justify-center gap-2 rounded-lg bg-gray-900 px-8 py-3
//                             text-white font-medium hover:bg-gray-800 transition-all duration-200
//                             hover:shadow-lg hover:shadow-gray-900/25"
//                 >
//                   Start Hiring
//                 </a>
//                 <div className="flex items-center gap-2 text-gray-600">
//                   <div className="flex -space-x-2">
//                     {[...Array(3)].map((_, i) => (
//                       <div
//                         key={i}
//                         className="w-8 h-8 rounded-full border-2 border-white bg-gray-200"
//                       />
//                     ))}
//                   </div>
//                   <span className="text-sm font-medium">
//                     Trusted by leading companies
//                   </span>
//                 </div>
//               </div>
//             </div>

//             {/* Right column - Stats grid */}
//             <div className="h-[400px] md:h-[500px] lg:h-[600px] grid grid-cols-2 gap-4 lg:gap-6 items-center">
//               {[
//                 {
//                   metric: '95%',
//                   label: 'Success Rate',
//                   sublabel: 'Post 6-month review',
//                 },
//                 {
//                   metric: '48h',
//                   label: 'Response Time',
//                   sublabel: 'Average first contact',
//                 },
//                 {
//                   metric: '10+',
//                   label: 'Enterprise Clients',
//                   sublabel: 'Globally served',
//                 },
//                 {
//                   metric: '100+',
//                   label: 'Placements',
//                   sublabel: 'Successfully matched',
//                 },
//               ].map((stat, i) => (
//                 <div
//                   key={i}
//                   className="relative group bg-white/90 backdrop-blur-sm rounded-xl border border-gray-100 p-6 shadow-sm
//                            hover:shadow-md hover:border-gray-200 transition-all duration-200"
//                 >
//                   <div className="flex flex-col">
//                     <div className="text-2xl lg:text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
//                       {stat.metric}
//                     </div>
//                     <div className="mt-2 text-sm font-medium text-gray-900">
//                       {stat.label}
//                     </div>
//                     <div className="mt-1 text-xs text-gray-500">
//                       {stat.sublabel}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default EmployerHero;

import React from 'react';

const EmployerHero = ({ googleFormLink }) => {
  return (
    <section className="relative overflow-hidden bg-white pt-16 pb-24 lg:pt-20 lg:pb-24">
      {/* Grid background to match TalentHero - unchanged */}
      <div
        className="absolute inset-0 opacity-30"
        aria-hidden="true"
        style={{
          backgroundSize: '40px 40px',
          backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px),
                           linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px)`,
          maskImage: 'linear-gradient(to right, black 60%, transparent)',
        }}
      />

      {/* Abstract background pattern - unchanged */}
      <div className="absolute inset-0 max-w-7xl mx-auto" aria-hidden="true">
        <div className="absolute right-0 top-0 -translate-y-12 opacity-10">
          <svg width="350" height="350" viewBox="0 0 200 200">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#2563eb" />
                <stop offset="100%" stopColor="#4f46e5" />
              </linearGradient>
            </defs>
            <path
              fill="url(#gradient)"
              d="M45,-57.2C54.8,-46.3,56.3,-29,59.1,-11.9C62,5.3,66.2,22.2,60.7,35.3C55.2,48.4,39.9,57.6,23.4,62.5C6.9,67.3,-10.8,67.8,-27.2,62.4C-43.6,57,-58.7,45.7,-65.4,30.5C-72.1,15.3,-70.4,-3.7,-63.3,-19.3C-56.2,-34.9,-43.7,-47,-29.8,-57.3C-15.9,-67.6,-0.7,-76.1,9.1,-71.9C18.9,-67.7,35.2,-68.1,45,-57.2Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </div>

      <div className="relative container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center min-h-[600px] lg:min-h-[700px]">
            {/* Left column - Main content */}
            <div className="max-w-xl lg:max-w-none">
              {/* Enterprise badge */}
              <div className="inline-flex items-center rounded-full bg-blue-50 px-3 py-1 mb-8 shadow-sm">
                <div className="h-2 w-2 rounded-full bg-blue-600 mr-2" />
                <span className="text-xs mobile:text-sm font-medium text-blue-900">
                  Enterprise Solutions
                </span>
              </div>

              {/* Main heading with gradient */}
              <h1 className="text-2xl mobile:text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-semibold tracking-tight mb-6">
                Build Your Elite
                <span className="block mt-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
                  Team
                </span>
              </h1>

              <p className="text-sm mobile:text-base sm:text-lg lg:text-xl text-gray-600 leading-relaxed mb-8 lg:mb-12">
                Access a curated network of top-tier tech talent, pre-vetted for
                enterprise standards. Scale your team with confidence.
              </p>

              {/* CTA section */}
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
                <a
                  href={googleFormLink}
                  className="group inline-flex items-center justify-center gap-2 rounded-lg bg-gray-900 px-8 py-3
                            text-xs mobile:text-sm sm:text-base text-white font-medium hover:bg-gray-800 transition-all duration-200
                            hover:shadow-lg hover:shadow-gray-900/25"
                >
                  Start Hiring
                </a>
                <div className="flex items-center gap-2 text-gray-600">
                  <div className="flex -space-x-2">
                    {[...Array(3)].map((_, i) => (
                      <div
                        key={i}
                        className="w-8 h-8 rounded-full border-2 border-white bg-gray-200"
                      />
                    ))}
                  </div>
                  <span className="text-xs mobile:text-sm font-medium">
                    Trusted by leading companies
                  </span>
                </div>
              </div>
            </div>

            {/* Right column - Stats grid */}
            <div className="h-[400px] md:h-[500px] lg:h-[600px] grid grid-cols-2 gap-4 lg:gap-6 items-center">
              {[
                {
                  metric: '95%',
                  label: 'Success Rate',
                  sublabel: 'Post 6-month review',
                },
                {
                  metric: '48h',
                  label: 'Response Time',
                  sublabel: 'Average first contact',
                },
                {
                  metric: '10+',
                  label: 'Enterprise Clients',
                  sublabel: 'Globally served',
                },
                {
                  metric: '100+',
                  label: 'Placements',
                  sublabel: 'Successfully matched',
                },
              ].map((stat, i) => (
                <div
                  key={i}
                  className="relative group bg-white/90 backdrop-blur-sm rounded-xl border border-gray-100 p-6 shadow-sm
                           hover:shadow-md hover:border-gray-200 transition-all duration-200"
                >
                  <div className="flex flex-col">
                    <div className="text-xl mobile:text-2xl lg:text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                      {stat.metric}
                    </div>
                    <div className="mt-2 text-xs mobile:text-sm font-medium text-gray-900">
                      {stat.label}
                    </div>
                    <div className="mt-1 text-[10px] mobile:text-xs text-gray-500">
                      {stat.sublabel}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployerHero;
