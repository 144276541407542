import React, { useState, useEffect } from 'react';
import videoFile from '../assets/videoHero.mp4';

const mathFormulas = [
  'E = mc²',
  'f(x) = ax² + bx + c',
  '∫ dx',
  'π = 3.14159',
  'x = -b ± √(b²-4ac)/2a',
  'P(A|B)',
  'Σ x = n(n+1)/2',
  '∂y/∂x',
  'e = 2.71828',
  'y = mx + b',
  'A ∩ B',
  '∆x → 0',
];

const Hero = () => {
  const [formulas, setFormulas] = useState([]);

  useEffect(() => {
    const container = document.querySelector('section');
    const bounds = container?.getBoundingClientRect();
    const containerWidth = bounds?.width || window.innerWidth;
    const containerHeight = bounds?.height || window.innerHeight;

    const initialFormulas = Array.from({ length: 8 }, (_, i) => ({
      id: i,
      text: mathFormulas[Math.floor(Math.random() * mathFormulas.length)],
      x: Math.random() * (containerWidth - 100),
      y: Math.random() * (containerHeight - 30),
      vx: (Math.random() - 0.5) * 2,
      vy: (Math.random() - 0.5) * 2,
      size: 0.8 + Math.random() * 0.4,
      opacity: 0.2 + Math.random() * 0.3,
    }));

    setFormulas(initialFormulas);

    const moveFormulas = () => {
      setFormulas((prevFormulas) =>
        prevFormulas.map((formula) => {
          let newX = formula.x + formula.vx;
          let newY = formula.y + formula.vy;
          let newVx = formula.vx;
          let newVy = formula.vy;

          const currentBounds = container?.getBoundingClientRect();
          const maxX = (currentBounds?.width || containerWidth) - 100;
          const maxY = (currentBounds?.height || containerHeight) - 30;

          if (newX < 20 || newX > maxX) {
            newVx = -formula.vx * 0.8;
            newX = newX < 20 ? 20 : maxX;
          }
          if (newY < 20 || newY > maxY) {
            newVy = -formula.vy * 0.8;
            newY = newY < 20 ? 20 : maxY;
          }

          return {
            ...formula,
            x: newX,
            y: newY,
            vx: newVx,
            vy: newVy,
          };
        })
      );
    };

    let animationFrameId;
    let lastTime = 0;
    const fps = 30;
    const fpsInterval = 1000 / fps;

    const animate = (currentTime) => {
      animationFrameId = requestAnimationFrame(animate);

      const elapsed = currentTime - lastTime;

      if (elapsed > fpsInterval) {
        lastTime = currentTime - (elapsed % fpsInterval);
        moveFormulas();
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return (
    <section className="relative w-auto min-h-[85vh] mobile:min-h-[70vh] bg-white mx-2 mobile:mx-3 sm:mx-4 md:mx-10 mt-2 mobile:mt-3 overflow-hidden rounded-2xl mobile:rounded-3xl">
      {/* Math Formulas Background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="relative w-full h-full">
          {formulas.map((formula) => (
            <div
              key={formula.id}
              className="absolute font-serif transition-transform duration-75"
              style={{
                left: formula.x,
                top: formula.y,
                transform: `scale(${formula.size})`,
                opacity: formula.opacity,
                color: '#4B5563',
                minWidth: 'fit-content',
              }}
            >
              {formula.text}
            </div>
          ))}
        </div>
      </div>

      <div className="grid lg:grid-cols-2 min-h-[85vh] mobile:min-h-[70vh]">
        {/* Content Side */}
        <div className="relative z-20 p-4 mobile:p-2 md:p-8 lg:p-8 flex items-center">
          <div className="relative max-w-xl mx-auto lg:mx-0">
            <div className="absolute inset-0 bg-white/0 lg:bg-white/30 backdrop-blur-sm rounded-xl" />
            <div className="relative">
              <h1 className="text-2xl mobile:text-4xl sm:text-4xl lg:text-5xl font-bold mb-3 mobile:mb-4 sm:mb-6 text-gray-900">
                Future-Proof Your Career
              </h1>
              <p className="text-sm mobile:text-lg sm:text-lg lg:text-2xl mb-4 mobile:mb-6 sm:mb-8 text-gray-600">
                Join our platform to access global job opportunities and
                industry-leading upskilling programs in AI-impacted sectors.
              </p>

              <div className="flex flex-col sm:flex-row gap-2 mobile:gap-3 sm:gap-4">
                <a
                  href="https://forms.gle/Nb2oqFQc4xq2CCjN8"
                  className="bg-teal-500 text-white font-semibold py-2 mobile:py-2.5 sm:py-3 px-4 mobile:px-6 sm:px-8 rounded-lg hover:bg-teal-600 transition duration-300 text-center text-xs mobile:text-sm sm:text-base"
                >
                  Explore Opportunities
                </a>
              </div>

              <p className="mt-3 mobile:mt-4 sm:mt-6 text-[10px] mobile:text-xs sm:text-sm text-gray-500">
                Partnering with leading companies and educational institutions
                to prepare talent for the AI era
              </p>
            </div>
          </div>
        </div>

        {/* Video Side - Desktop */}
        <div className="relative lg:block hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-white via-transparent to-transparent z-10" />
          <video
            className="h-full w-full object-cover bg-white"
            src={videoFile}
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
          />
          <div className="absolute inset-0 bg-black opacity-30" />
        </div>

        {/* Video Background - Mobile/Tablet */}
        <div className="lg:hidden absolute inset-0 -z-10">
          <div className="absolute inset-0 bg-gradient-to-b from-white via-white/80 to-white/50 z-10" />
          <video
            className="h-full w-full object-cover bg-white"
            src={videoFile}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="absolute inset-0 bg-black opacity-20" />
        </div>
      </div>

      {/* Stats Section */}
      <div className="absolute bottom-3 mobile:bottom-4 sm:bottom-8 left-3 mobile:left-4 sm:left-8 right-3 mobile:right-4 sm:right-8 z-30">
        <div className="bg-white/30 lg:bg-white/80 backdrop-blur-sm rounded-lg mobile:rounded-xl p-3 mobile:p-4 sm:p-6 shadow-lg">
          <div className="grid grid-cols-3 gap-2 mobile:gap-3 sm:gap-8">
            <div className="text-center">
              <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
                500+
              </div>
              <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
                Global Opportunities
              </div>
            </div>
            <div className="text-center">
              <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
                10+
              </div>
              <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
                Industry Partners
              </div>
            </div>
            <div className="text-center">
              <div className="font-bold text-base mobile:text-lg sm:text-2xl text-teal-600">
                85%
              </div>
              <div className="text-[10px] mobile:text-xs sm:text-sm text-gray-600">
                Success Rate
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
