// import React from 'react';
// import {
//   Cpu,
//   Cloud,
//   Brain,
//   Battery,
//   Sun,
//   Car,
//   Factory,
//   Bot,
// } from 'lucide-react';

// const FutureIndustriesList = () => {
//   const industries = [
//     {
//       Icon: Brain,
//       title: 'AI & Machine Learning',
//       description:
//         'From neural networks to RobotIconics control systems, master the technologies driving autonomous decision-making',
//       skills: [
//         'Deep Learning',
//         'Computer Vision',
//         'NLP Systems',
//         'AI Hardware',
//       ],
//     },
//     {
//       Icon: Cpu,
//       title: 'Semiconductor Manufacturing',
//       description:
//         'Build the foundation of modern computing with expertise in advanced chip fabrication and design',
//       skills: [
//         'Wafer Processing',
//         'Lithography',
//         'Clean Room Operations',
//         'Quality Control',
//       ],
//     },
//     {
//       Icon: Battery,
//       title: 'Advanced Battery Tech',
//       description:
//         'Power the future with skills in cutting-edge battery manufacturing and energy storage solutions',
//       skills: [
//         'Cell Production',
//         'Material Science',
//         'Testing Systems',
//         'Quality Assurance',
//       ],
//     },
//     {
//       Icon: Sun,
//       title: 'Renewable Technology',
//       description:
//         'Shape sustainable energy production with expertise in solar, wind, and storage manufacturing',
//       skills: ['Panel Production', 'Clean Energy', 'Smart Grids', 'Green Tech'],
//     },
//     {
//       Icon: Car,
//       title: 'EV Manufacturing',
//       description:
//         'Drive the electric revolution with skills in modern vehicle production and battery integration',
//       skills: [
//         'Powertrain Assembly',
//         'Battery Systems',
//         'Quality Control',
//         'Production',
//       ],
//     },
//     {
//       Icon: Bot,
//       title: 'Robotics & Automation',
//       description:
//         'Build and maintain the automated systems that power modern manufacturing',
//       skills: [
//         'Robot Programming',
//         'System Integration',
//         'Maintenance',
//         'Controls',
//       ],
//     },
//     {
//       Icon: Cloud,
//       title: 'Cloud Computing',
//       description:
//         'Master cloud infrastructure and services that power modern industrial operations',
//       skills: ['Cloud Architecture', 'DevOps', 'Security', 'Infrastructure'],
//     },
//     {
//       Icon: Factory,
//       title: 'Smart Manufacturing',
//       description:
//         'Integrate IoT, AI, and automation for next-generation industrial processes',
//       skills: ['IoT Systems', 'Digital Twin', 'Process Control', 'Analytics'],
//     },
//   ];

//   return (
//     <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
//       <div className="max-w-7xl mx-auto px-6">
//         <div className="text-center mb-16">
//           <span className="inline-block px-4 py-1 bg-blue-100 text-blue-700 rounded-full text-sm font-medium mb-4">
//             Future-Ready Training
//           </span>
//           <h2 className="text-4xl font-bold text-gray-900 mb-6">
//             Building Tomorrow's Tech Leaders
//           </h2>
//           <p className="text-xl text-gray-600 max-w-3xl mx-auto">
//             Preparing talent for industries of the future. Our industry led
//             partnerships prepare you for the most in-demand roles in advanced
//             manufacturing and emerging technologies.
//           </p>
//         </div>

//         <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
//           {industries.map((industry, index) => (
//             <div
//               key={index}
//               className="group bg-white rounded-xl p-6 hover:shadow-xl transition-all duration-300 border border-gray-100 relative overflow-hidden"
//             >
//               {/* Decorative gradient background */}
//               <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

//               <div className="relative">
//                 <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
//                   <industry.Icon className="w-6 h-6 text-blue-600" />
//                 </div>

//                 <h3 className="text-xl font-bold text-gray-900 mb-3">
//                   {industry.title}
//                 </h3>

//                 <p className="text-gray-600 mb-4 text-sm">
//                   {industry.description}
//                 </p>

//                 <div className="flex flex-wrap gap-2">
//                   {industry.skills.map((skill, idx) => (
//                     <span
//                       key={idx}
//                       className="inline-block px-3 py-1 bg-gray-50 text-gray-700 rounded-full text-sm group-hover:bg-blue-50 group-hover:text-blue-700 transition-colors duration-300"
//                     >
//                       {skill}
//                     </span>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         <div className="mt-16 text-center">
//           {/* <button className="inline-flex items-center justify-center px-6 py-3 rounded-lg bg-blue-600 text-white font-semibold hover:bg-blue-700 transition-colors duration-200">
//             Explore Training Programs
//           </button> */}
//           <p className="mt-4 text-gray-600">
//             Programs starting monthly • Industry-recognized certifications •
//             Career placement support
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FutureIndustriesList;

import React from 'react';
import {
  Cpu,
  Cloud,
  Brain,
  Battery,
  Sun,
  Car,
  Factory,
  Bot,
} from 'lucide-react';

const FutureIndustriesList = () => {
  const industries = [
    {
      Icon: Brain,
      title: 'AI & Machine Learning',
      description:
        'From neural networks to RobotIconics control systems, master the technologies driving autonomous decision-making',
      skills: [
        'Deep Learning',
        'Computer Vision',
        'NLP Systems',
        'AI Hardware',
      ],
    },
    {
      Icon: Cpu,
      title: 'Semiconductor Manufacturing',
      description:
        'Build the foundation of modern computing with expertise in advanced chip fabrication and design',
      skills: [
        'Wafer Processing',
        'Lithography',
        'Clean Room Operations',
        'Quality Control',
      ],
    },
    {
      Icon: Battery,
      title: 'Advanced Battery Tech',
      description:
        'Power the future with skills in cutting-edge battery manufacturing and energy storage solutions',
      skills: [
        'Cell Production',
        'Material Science',
        'Testing Systems',
        'Quality Assurance',
      ],
    },
    {
      Icon: Sun,
      title: 'Renewable Technology',
      description:
        'Shape sustainable energy production with expertise in solar, wind, and storage manufacturing',
      skills: ['Panel Production', 'Clean Energy', 'Smart Grids', 'Green Tech'],
    },
    {
      Icon: Car,
      title: 'EV Manufacturing',
      description:
        'Drive the electric revolution with skills in modern vehicle production and battery integration',
      skills: [
        'Powertrain Assembly',
        'Battery Systems',
        'Quality Control',
        'Production',
      ],
    },
    {
      Icon: Bot,
      title: 'Robotics & Automation',
      description:
        'Build and maintain the automated systems that power modern manufacturing',
      skills: [
        'Robot Programming',
        'System Integration',
        'Maintenance',
        'Controls',
      ],
    },
    {
      Icon: Cloud,
      title: 'Cloud Computing',
      description:
        'Master cloud infrastructure and services that power modern industrial operations',
      skills: ['Cloud Architecture', 'DevOps', 'Security', 'Infrastructure'],
    },
    {
      Icon: Factory,
      title: 'Smart Manufacturing',
      description:
        'Integrate IoT, AI, and automation for next-generation industrial processes',
      skills: ['IoT Systems', 'Digital Twin', 'Process Control', 'Analytics'],
    },
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-6">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-1 bg-blue-100 text-blue-700 rounded-full text-xs mobile:text-sm font-medium mb-4">
            Future-Ready Training
          </span>
          <h2 className="text-2xl mobile:text-3xl sm:text-4xl font-bold text-gray-900 mb-6">
            Building Tomorrow's Tech Leaders
          </h2>
          <p className="text-sm mobile:text-base sm:text-lg lg:text-xl text-gray-600 max-w-3xl mx-auto">
            Preparing talent for industries of the future. Our industry led
            partnerships prepare you for the most in-demand roles in advanced
            manufacturing and emerging technologies.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {industries.map((industry, index) => (
            <div
              key={index}
              className="group bg-white rounded-xl p-6 hover:shadow-xl transition-all duration-300 border border-gray-100 relative overflow-hidden"
            >
              {/* Decorative gradient background */}
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

              <div className="relative">
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
                  <industry.Icon className="w-6 h-6 text-blue-600" />
                </div>

                <h3 className="text-lg mobile:text-xl font-bold text-gray-900 mb-3">
                  {industry.title}
                </h3>

                <p className="text-xs mobile:text-sm text-gray-600 mb-4">
                  {industry.description}
                </p>

                <div className="flex flex-wrap gap-2">
                  {industry.skills.map((skill, idx) => (
                    <span
                      key={idx}
                      className="inline-block px-3 py-1 bg-gray-50 text-gray-700 rounded-full text-[10px] mobile:text-xs group-hover:bg-blue-50 group-hover:text-blue-700 transition-colors duration-300"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <p className="mt-4 text-xs mobile:text-sm text-gray-600">
            Programs starting monthly • Industry-recognized certifications •
            Career placement support
          </p>
        </div>
      </div>
    </section>
  );
};

export default FutureIndustriesList;
