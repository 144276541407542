// import React from 'react';
// import { Globe, Shield, Users, ArrowRight } from 'lucide-react';

// const ServicesSection = () => {
//   const services = [
//     {
//       Icon: Globe,
//       title: 'Global Talent Access',
//       description:
//         'Access top-tier international talent for both temporary and permanent positions, with comprehensive support for remote and relocating workers.',
//       points: [
//         'Global talent pool access',
//         'Remote hiring expertise',
//         'Relocation assistance',
//         'Cultural fit assessment',
//       ],
//     },
//     {
//       Icon: Shield,
//       title: 'Compliance Management',
//       description:
//         'End-to-end handling of international hiring compliance, work permits, and legal requirements across different jurisdictions.',
//       points: [
//         'Visa processing',
//         'Work permit handling',
//         'Legal documentation',
//         'Regulatory compliance',
//       ],
//     },
//     {
//       Icon: Users,
//       title: 'Workforce Solutions',
//       description:
//         'Flexible staffing solutions for both temporary and permanent positions, tailored to your specific business needs.',
//       points: [
//         'Contract staffing',
//         'Permanent placement',
//         'Team augmentation',
//         'Skill-based hiring',
//       ],
//     },
//   ];

//   return (
//     <section className="py-24 bg-gray-50">
//       <div className="max-w-7xl mx-auto px-6">
//         <div className="text-center mb-20">
//           <span className="inline-block px-3 py-1 text-sm font-semibold text-blue-600 bg-blue-50 rounded-full mb-4">
//             Our Services
//           </span>
//           <h2 className="text-4xl font-bold text-gray-900 mb-4">
//             Comprehensive Global Hiring Solutions
//           </h2>
//           <p className="text-xl text-gray-600 max-w-2xl mx-auto">
//             Everything you need to hire and manage global talent, whether
//             temporary or permanent
//           </p>
//         </div>

//         <div className="grid lg:grid-cols-3 gap-8">
//           {services.map((service, index) => (
//             <div
//               key={index}
//               className="group bg-white rounded-2xl overflow-hidden transform hover:-translate-y-1 transition-all duration-300 hover:shadow-2xl"
//             >
//               <div className="p-8">
//                 <div className="flex items-center gap-4 mb-6">
//                   <div className="w-12 h-12 rounded-xl bg-blue-600 flex items-center justify-center transform rotate-3 group-hover:rotate-6 transition-transform duration-300">
//                     <service.Icon className="w-6 h-6 text-white" />
//                   </div>
//                   <h3 className="text-xl font-bold text-gray-900">
//                     {service.title}
//                   </h3>
//                 </div>

//                 <p className="text-gray-600 mb-8">{service.description}</p>

//                 <div className="space-y-4">
//                   {service.points.map((point, idx) => (
//                     <div
//                       key={idx}
//                       className="flex items-center gap-3 p-3 rounded-lg bg-gray-50 group-hover:bg-blue-50 transition-colors duration-300"
//                     >
//                       <ArrowRight className="w-4 h-4 text-blue-600" />
//                       <span className="text-gray-700">{point}</span>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ServicesSection;

import React from 'react';
import { Globe, Shield, Users, ArrowRight } from 'lucide-react';

const ServicesSection = () => {
  const services = [
    {
      Icon: Globe,
      title: 'Global Talent Access',
      description:
        'Access top-tier international talent for both temporary and permanent positions, with comprehensive support for remote and relocating workers.',
      points: [
        'Global talent pool access',
        'Remote hiring expertise',
        'Relocation assistance',
        'Cultural fit assessment',
      ],
    },
    {
      Icon: Shield,
      title: 'Compliance Management',
      description:
        'End-to-end handling of international hiring compliance, work permits, and legal requirements across different jurisdictions.',
      points: [
        'Visa processing',
        'Work permit handling',
        'Legal documentation',
        'Regulatory compliance',
      ],
    },
    {
      Icon: Users,
      title: 'Workforce Solutions',
      description:
        'Flexible staffing solutions for both temporary and permanent positions, tailored to your specific business needs.',
      points: [
        'Contract staffing',
        'Permanent placement',
        'Team augmentation',
        'Skill-based hiring',
      ],
    },
  ];

  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-6">
        <div className="text-center mb-20">
          <span className="inline-block px-3 py-1 text-xs mobile:text-sm font-semibold text-blue-600 bg-blue-50 rounded-full mb-4">
            Our Services
          </span>
          <h2 className="text-2xl mobile:text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            Comprehensive Global Hiring Solutions
          </h2>
          <p className="text-sm mobile:text-base sm:text-lg lg:text-xl text-gray-600 max-w-2xl mx-auto">
            Everything you need to hire and manage global talent, whether
            temporary or permanent
          </p>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="group bg-white rounded-2xl overflow-hidden transform hover:-translate-y-1 transition-all duration-300 hover:shadow-2xl"
            >
              <div className="p-8">
                <div className="flex items-center gap-4 mb-6">
                  <div className="w-12 h-12 rounded-xl bg-blue-600 flex items-center justify-center transform rotate-3 group-hover:rotate-6 transition-transform duration-300">
                    <service.Icon className="w-6 h-6 text-white" />
                  </div>
                  <h3 className="text-lg mobile:text-xl font-bold text-gray-900">
                    {service.title}
                  </h3>
                </div>

                <p className="text-xs mobile:text-sm text-gray-600 mb-8">
                  {service.description}
                </p>

                <div className="space-y-4">
                  {service.points.map((point, idx) => (
                    <div
                      key={idx}
                      className="flex items-center gap-3 p-3 rounded-lg bg-gray-50 group-hover:bg-blue-50 transition-colors duration-300"
                    >
                      <ArrowRight className="w-4 h-4 text-blue-600" />
                      <span className="text-xs mobile:text-sm text-gray-700">
                        {point}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
