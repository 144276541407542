// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   Globe,
//   GraduationCap,
//   Building,
//   CheckCircle,
//   ArrowRight,
// } from 'lucide-react';
// import { motion } from 'framer-motion';

// export default function ProgramHighlight() {
//   const navigate = useNavigate();

//   const highlights = [
//     {
//       icon: Globe,
//       title: 'German Market Access',
//       description:
//         'Part of 90,000 visas being offered to Indian professionals in 2024',
//       points: [
//         'Skilled worker visa pathway',
//         'High demand job sectors',
//         'Regulated profession guidance',
//       ],
//     },
//     {
//       icon: GraduationCap,
//       title: 'German Language & Skills',
//       description: 'Intensive language training with industry-specific focus',
//       points: [
//         'A1 to B2 German proficiency',
//         'Technical vocabulary training',
//         'Cultural integration modules',
//       ],
//     },
//     {
//       icon: Building,
//       title: 'Career Launch Support',
//       description: 'End-to-end assistance for your German career journey',
//       points: [
//         'Job market preparation',
//         'Interview coaching',
//         'Relocation assistance',
//       ],
//     },
//   ];

//   const stats = [
//     { value: '90K', label: 'Visas for Indians' },
//     { value: '85%', label: 'Placement Rate*' },
//     { value: '6-8', label: 'Months Duration' },
//   ];

//   return (
//     <section className="py-20 px-4 relative overflow-hidden">
//       <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-blue-500/5" />

//       <div className="max-w-7xl mx-auto relative z-10">
//         {/* <div className="text-center mb-16">
//           <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium mb-4 inline-block">
//             Germany Skilled Migration 2024
//           </span>
//           <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
//             Your Gateway to Germany's Job Market
//           </h2>
//           <p className="text-lg text-gray-600 max-w-2xl mx-auto">
//             Take advantage of Germany's initiative to welcome 90,000 skilled
//             Indian professionals. Get trained, certified, and placed in
//             Germany's thriving job market.
//           </p>
//         </div> */}
//         <ProgramHeader />

//         <div className="grid md:grid-cols-3 gap-8 mb-16">
//           {highlights.map(({ icon: Icon, title, description, points }) => (
//             <div
//               key={title}
//               className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
//             >
//               <div className="bg-blue-500 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
//                 <Icon className="w-6 h-6 text-white" />
//               </div>
//               <h3 className="text-xl font-bold text-gray-900 mb-3">{title}</h3>
//               <p className="text-gray-600 mb-4">{description}</p>
//               <ul className="space-y-2">
//                 {points.map((point, index) => (
//                   <li key={index} className="flex items-start gap-2">
//                     <CheckCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
//                     <span className="text-gray-700">{point}</span>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//         </div>

//         <div className="bg-blue-500 rounded-2xl p-10 mb-16">
//           <div className="grid grid-cols-3 gap-8 max-w-3xl mx-auto">
//             {stats.map(({ value, label }) => (
//               <div key={label} className="text-center">
//                 <div className="text-3xl font-bold text-white mb-1">
//                   {value}
//                 </div>
//                 <div className="text-sm text-blue-100">{label}</div>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="text-center">
//           <button
//             onClick={() => navigate('/germany')}
//             className="group inline-flex items-center gap-2 bg-blue-500 text-white px-8 py-3 rounded-full text-lg font-medium hover:bg-blue-600 transition-all duration-300"
//           >
//             Start Your German Journey
//             <ArrowRight className="group-hover:translate-x-1 transition-transform duration-300" />
//           </button>
//           <p className="mt-4 text-gray-600 text-sm">
//             Limited spots available for the upcoming batch
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// }

// const ProgramHeader = () => {
//   return (
//     <div className="text-center mb-16">
//       <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
//         Your Path to Germany's Job Market
//       </h2>
//       <p className="text-lg text-gray-600 max-w-3xl mx-auto">
//         Germany is welcoming{' '}
//         <motion.span
//           initial={{ opacity: 0, scale: 2 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{
//             duration: 0.1,
//             repeat: Infinity,
//             repeatType: 'reverse',
//             repeatDelay: 0,
//           }}
//           className="inline-flex items-center justify-center font-bold text-blue-500"
//         >
//           90,000
//         </motion.span>{' '}
//         Indian professionals{' '}
//         <span className="text-sm font-medium text-gray-500">per year</span>
//       </p>
//     </div>
//   );
// };

// import React, { useState } from 'react';
// import {
//   Globe,
//   GraduationCap,
//   Building,
//   CheckCircle,
//   ArrowRight,
//   Users,
//   Clock,
//   Sparkles,
// } from 'lucide-react';

// const ProgramHighlight = () => {
//   const [hoveredCard, setHoveredCard] = useState(null);

//   const highlights = [
//     {
//       icon: Globe,
//       title: 'German Market Access',
//       description:
//         'Part of 90,000 visas being offered to Indian professionals in 2024',
//       points: [
//         'Skilled worker visa pathway',
//         'High demand job sectors',
//         'Regulated profession guidance',
//       ],
//       color: 'from-blue-500 to-indigo-600',
//       hoverEffect: 'hover:shadow-blue-200',
//     },
//     {
//       icon: GraduationCap,
//       title: 'German Language & Skills',
//       description: 'Intensive language training with industry-specific focus',
//       points: [
//         'A1 to B2 German proficiency',
//         'Technical vocabulary training',
//         'Cultural integration modules',
//       ],
//       color: 'from-emerald-500 to-teal-600',
//       hoverEffect: 'hover:shadow-emerald-200',
//     },
//     {
//       icon: Building,
//       title: 'Career Launch Support',
//       description: 'End-to-end assistance for your German career journey',
//       points: [
//         'Job market preparation',
//         'Interview coaching',
//         'Relocation assistance',
//       ],
//       color: 'from-purple-500 to-violet-600',
//       hoverEffect: 'hover:shadow-purple-200',
//     },
//   ];

//   const stats = [
//     {
//       value: '90K',
//       label: 'Visas for Indians',
//       icon: Users,
//       color: 'text-blue-500',
//     },
//     {
//       value: '85%',
//       label: 'Success Rate*',
//       icon: Sparkles,
//       color: 'text-emerald-500',
//     },
//     {
//       value: '6-8',
//       label: 'Months Duration',
//       icon: Clock,
//       color: 'text-purple-400',
//     },
//   ];

//   return (
//     <section className="py-16 shadow-sm px-4 relative bg-gray-50">
//       {/* Background pattern */}
//       <div className="absolute inset-0 opacity-40">
//         <div className="absolute inset-y-0 left-0 w-1/2 bg-gradient-to-r from-blue-50 to-transparent" />
//         <div className="absolute top-0 left-0 right-0 h-1/2 bg-gradient-to-b from-white to-transparent" />
//       </div>

//       <div className="max-w-7xl mx-auto relative">
//         {/* Header Section */}
//         <div className="text-center mb-20">
//           <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-50 mb-6">
//             <Sparkles className="w-4 h-4 text-blue-500 mr-2" />
//             <span className="text-sm font-medium text-blue-700">
//               Germany Skilled Migration 2024
//             </span>
//           </div>

//           <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
//             Your Pathway to a
//             <span className="relative inline-block ml-3 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
//               Career in Germany
//               <div className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-full" />
//             </span>
//           </h1>

//           <p className="mobile:text-sm text-lg text-gray-600 max-w-3xl mx-auto leading-relaxed">
//             Join the wave of{' '}
//             <span className="font-semibold text-blue-600">
//               90,000 Indian professionals
//             </span>{' '}
//             being welcomed into Germany's thriving job market. Transform your
//             career with our comprehensive program.
//           </p>
//         </div>

//         {/* Feature Cards */}
//         <div className="grid md:grid-cols-3 gap-8 mb-20">
//           {highlights.map((highlight, index) => (
//             <div
//               key={index}
//               className={`relative bg-white rounded-2xl p-8 transition-all duration-500 transform hover:-translate-y-2 ${
//                 highlight.hoverEffect
//               } shadow-lg`}
//               onMouseEnter={() => setHoveredCard(index)}
//               onMouseLeave={() => setHoveredCard(null)}
//             >
//               <div
//                 className={`bg-gradient-to-r ${highlight.color} w-14 h-14 rounded-xl flex items-center justify-center mb-6 shadow-lg`}
//               >
//                 <highlight.icon className="w-7 h-7 text-white" />
//               </div>

//               <h3 className="text-2xl font-bold text-gray-900 mb-4">
//                 {highlight.title}
//               </h3>
//               <p className="text-gray-600 mb-6 leading-relaxed">
//                 {highlight.description}
//               </p>

//               <ul className="space-y-3">
//                 {highlight.points.map((point, pIndex) => (
//                   <li
//                     key={pIndex}
//                     className={`flex items-start gap-3 transition-all duration-300 ${
//                       hoveredCard === index ? 'transform translate-x-2' : ''
//                     }`}
//                   >
//                     <CheckCircle
//                       className={`w-5 h-5 flex-shrink-0 mt-0.5 transition-colors duration-300 ${
//                         hoveredCard === index
//                           ? `text-${highlight.color.split('-')[1]}-500`
//                           : 'text-gray-400'
//                       }`}
//                     />
//                     <span className="text-gray-700">{point}</span>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//         </div>

//         {/* Stats Section */}
//         <div className="bg-white rounded-3xl p-12 mb-16 shadow-xl">
//           <div className="grid md:grid-cols-3 gap-12">
//             {stats.map((stat, index) => (
//               <div
//                 key={index}
//                 className="text-center transform hover:scale-105 transition-transform duration-300"
//               >
//                 <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-50 mb-4">
//                   <stat.icon className={`w-8 h-8 ${stat.color}`} />
//                 </div>
//                 <div className={`text-4xl font-bold mb-2 ${stat.color}`}>
//                   {stat.value}
//                 </div>
//                 <div className="text-gray-600">{stat.label}</div>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* CTA Section */}
//         <div className="text-center">
//           <button
//             onClick={() =>
//               (window.location.href =
//                 'https://path-to-germany.talentfirst.global')
//             }
//             className="group relative inline-flex items-center gap-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-8 py-4 rounded-full text-lg font-medium overflow-hidden transition-all duration-300 hover:shadow-2xl hover:shadow-blue-200"
//           >
//             <span className="relative z-10">Start Your Journey Today</span>
//             <ArrowRight className="w-5 h-5 relative z-10 group-hover:translate-x-1 transition-transform duration-300" />
//             <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
//           </button>

//           <p className="mt-6 text-gray-600">
//             <span className="font-medium">Limited spots available</span> • Next
//             batch starting soon
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ProgramHighlight;

import React, { useState } from 'react';
import {
  Globe,
  GraduationCap,
  Building,
  CheckCircle,
  ArrowRight,
  Users,
  Clock,
  Sparkles,
} from 'lucide-react';

const ProgramHighlight = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const highlights = [
    {
      icon: Globe,
      title: 'German Market Access',
      description:
        'Part of 90,000 visas being offered to Indian professionals in 2024',
      points: [
        'Skilled worker visa pathway',
        'High demand job sectors',
        'Regulated profession guidance',
      ],
      color: 'from-blue-500 to-indigo-600',
      hoverEffect: 'hover:shadow-blue-200',
    },
    {
      icon: GraduationCap,
      title: 'German Language & Skills',
      description: 'Intensive language training with industry-specific focus',
      points: [
        'A1 to B2 German proficiency',
        'Technical vocabulary training',
        'Cultural integration modules',
      ],
      color: 'from-emerald-500 to-teal-600',
      hoverEffect: 'hover:shadow-emerald-200',
    },
    {
      icon: Building,
      title: 'Career Launch Support',
      description: 'End-to-end assistance for your German career journey',
      points: [
        'Job market preparation',
        'Interview coaching',
        'Relocation assistance',
      ],
      color: 'from-purple-500 to-violet-600',
      hoverEffect: 'hover:shadow-purple-200',
    },
  ];

  const stats = [
    {
      value: '90K',
      label: 'Visas for Indians',
      icon: Users,
      color: 'text-blue-500',
    },
    {
      value: '85%',
      label: 'Success Rate*',
      icon: Sparkles,
      color: 'text-emerald-500',
    },
    {
      value: '6-8',
      label: 'Months Duration',
      icon: Clock,
      color: 'text-purple-400',
    },
  ];

  return (
    <section className="py-8 mobile:py-12 sm:py-16 shadow-sm px-3 mobile:px-4 relative bg-gray-50">
      {/* Background pattern */}
      <div className="absolute inset-0 opacity-40">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gradient-to-r from-blue-50 to-transparent" />
        <div className="absolute top-0 left-0 right-0 h-1/2 bg-gradient-to-b from-white to-transparent" />
      </div>

      <div className="max-w-7xl mx-auto relative">
        {/* Header Section */}
        <div className="text-center mb-12 mobile:mb-16 sm:mb-20">
          <div className="inline-flex items-center px-3 mobile:px-4 py-1.5 mobile:py-2 rounded-full bg-blue-50 mb-4 mobile:mb-6">
            <Sparkles className="w-3 h-3 mobile:w-4 mobile:h-4 text-blue-500 mr-2" />
            <span className="text-xs mobile:text-sm font-medium text-blue-700">
              Germany Skilled Migration 2024
            </span>
          </div>

          <h1 className="text-2xl mobile:text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-4 mobile:mb-6">
            Your Pathway to a
            <span className="relative inline-block ml-2 mobile:ml-3 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
              Career in Germany
              <div className="absolute -bottom-1 mobile:-bottom-2 left-0 w-full h-0.5 mobile:h-1 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-full" />
            </span>
          </h1>

          <p className="text-sm mobile:text-base sm:text-lg text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Join the wave of{' '}
            <span className="font-semibold text-blue-600">
              90,000 Indian professionals
            </span>{' '}
            being welcomed into Germany's thriving job market. Transform your
            career with our comprehensive program.
          </p>
        </div>

        {/* Feature Cards */}
        <div className="grid md:grid-cols-3 gap-4 mobile:gap-6 sm:gap-8 mb-12 mobile:mb-16 sm:mb-20">
          {highlights.map((highlight, index) => (
            <div
              key={index}
              className={`relative bg-white rounded-xl mobile:rounded-2xl p-6 mobile:p-8 transition-all duration-500 transform hover:-translate-y-2 ${
                highlight.hoverEffect
              } shadow-lg`}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div
                className={`bg-gradient-to-r ${highlight.color} w-12 mobile:w-14 h-12 mobile:h-14 rounded-lg mobile:rounded-xl flex items-center justify-center mb-4 mobile:mb-6 shadow-lg`}
              >
                <highlight.icon className="w-6 mobile:w-7 h-6 mobile:h-7 text-white" />
              </div>

              <h3 className="text-xl mobile:text-2xl font-bold text-gray-900 mb-3 mobile:mb-4">
                {highlight.title}
              </h3>
              <p className="text-sm mobile:text-base text-gray-600 mb-4 mobile:mb-6 leading-relaxed">
                {highlight.description}
              </p>

              <ul className="space-y-2 mobile:space-y-3">
                {highlight.points.map((point, pIndex) => (
                  <li
                    key={pIndex}
                    className={`flex items-start gap-2 mobile:gap-3 transition-all duration-300 ${
                      hoveredCard === index ? 'transform translate-x-2' : ''
                    }`}
                  >
                    <CheckCircle
                      className={`w-4 mobile:w-5 h-4 mobile:h-5 flex-shrink-0 mt-0.5 transition-colors duration-300 ${
                        hoveredCard === index
                          ? `text-${highlight.color.split('-')[1]}-500`
                          : 'text-gray-400'
                      }`}
                    />
                    <span className="text-sm mobile:text-base text-gray-700">
                      {point}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Stats Section */}
        <div className="bg-white rounded-2xl mobile:rounded-3xl p-8 mobile:p-10 sm:p-12 mb-12 mobile:mb-16 shadow-xl">
          <div className="grid md:grid-cols-3 gap-8 mobile:gap-10 sm:gap-12">
            {stats.map((stat, index) => (
              <div
                key={index}
                className="text-center transform hover:scale-105 transition-transform duration-300"
              >
                <div className="inline-flex items-center justify-center w-12 mobile:w-14 sm:w-16 h-12 mobile:h-14 sm:h-16 rounded-full bg-gray-50 mb-3 mobile:mb-4">
                  <stat.icon
                    className={`w-6 mobile:w-7 sm:w-8 h-6 mobile:h-7 sm:h-8 ${stat.color}`}
                  />
                </div>
                <div
                  className={`text-2xl mobile:text-3xl sm:text-4xl font-bold mb-1 mobile:mb-2 ${stat.color}`}
                >
                  {stat.value}
                </div>
                <div className="text-sm mobile:text-base text-gray-600">
                  {stat.label}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <button
            onClick={() =>
              (window.location.href =
                'https://path-to-germany.talentfirst.global')
            }
            className="group relative inline-flex items-center gap-2 mobile:gap-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-6 mobile:px-8 py-3 mobile:py-4 rounded-full text-sm mobile:text-base sm:text-lg font-medium overflow-hidden transition-all duration-300 hover:shadow-2xl hover:shadow-blue-200"
          >
            <span className="relative z-10">Start Your Journey Today</span>
            <ArrowRight className="w-4 mobile:w-5 h-4 mobile:h-5 relative z-10 group-hover:translate-x-1 transition-transform duration-300" />
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </button>

          <p className="mt-4 mobile:mt-6 text-xs mobile:text-sm text-gray-600">
            <span className="font-medium">Limited spots available</span> • Next
            batch starting soon
          </p>
        </div>
      </div>
    </section>
  );
};

export default ProgramHighlight;
